import "../styles/preflight.css";
import "../styles/globals.css";
import { appWithTranslation } from "next-i18next";

import type { AppProps } from "next/app";

import { useRouter } from "next/router";
import React, { useEffect } from "react";

function MyApp({ Component, pageProps }: AppProps) {
  const { pathname } = useRouter();

  useEffect(() => {
    window.history.scrollRestoration = "manual";
  }, [pathname]);

  return (
    <>
      <Component {...pageProps} />
    </>
  );
}

//export default MyApp;
export default appWithTranslation(MyApp);
